.rules-dia{
    height: max-content;
    max-height: 80%;

}
#agree-btns{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: 2rem auto;
}
#agree-btns>button{
    max-width: 100%;
    text-wrap: wrap;
    flex-grow: 1;
}
#agree{
    box-shadow: 0px 0px 4px 2px var(--theme-color);
    text-shadow: 0px 0px 4px var(--glow-color);
    background-color: var(--theme-glass-color);
    border: 1px solid transparent;
}
#agree:hover{
    border: 1px solid var(--glow-color);
    box-shadow: 0px 0px 8px 4px var(--theme-color);
}

@media (max-width: 768px) {
    .rules-dia{
        width: 95%;
    }
    #agree-btns>button{
        border-radius: 10px;
    }    
}