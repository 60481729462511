/* .icon-btn{
    width: 12rem;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    border: 1px solid var(--glow-color);
    box-shadow: 0px 0px 4px 2px var(--theme-color);

    a, div{
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        color: var(--text) !important;
        text-decoration: none !important;
        text-shadow: 0px 0px 2px var(--glow-color);
    }
    

    img{
        width: 50%;
    }
} */
.icon-btn{
    width: 12rem;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    border: 1px solid var(--glow-color);
    box-shadow: 0px 0px 4px 2px var(--theme-color);
}
.icon-btn>a, .icon-btn>div{
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        color: var(--text) !important;
        text-decoration: none !important;
        text-shadow: 0px 0px 2px var(--glow-color);
}
.icon-btn>a>img, .icon-btn>div>img{
    width: 50%;
}

.icon-btn:hover{
    box-shadow: 0px 0px 10px 5px var(--theme-color);
}