header{
    padding: 3rem 2rem 2rem 2rem;
}

.stander-header{
    position: fixed;
    top: 0px;
    width: 100%;
    min-height: 6rem;
    display: grid;
    grid-template-columns: auto 1fr auto auto auto;
    grid-template-areas: "logo nav scta cta loca";
    gap: 0rem min(2vw, 2rem);
    background-color: var(--theme-dark);
    backdrop-filter: blur(10px);
    /* margin: 2rem auto 1rem auto; */
    transition: all 1s;
    z-index: 100;
}


#brand-name{
    height: max-content;
    position: absolute;
    width: max-content;
    top: 50%;
    transform: translate(0, -40%);
    margin: 0px;
    left: 112px;
    color: var(--text);

    font-size: 1.2rem;

    animation: text-glowing 3s infinite alternate;
}
@keyframes text-glowing{
    0%  {text-shadow: 0px 0px 5px #ffffff;}
    25%  {text-shadow: 0px 0px 10px #ffffff;}
    75%  {text-shadow: 0px 0px 7px #ffffff;}
    100%  {text-shadow: 0px 0px 15px #ffffff;}
}
#brand-wrapper{
    grid-area: logo;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow: hidden;
    position: relative;
    /* box-shadow: 0px 0px 4px var(--glow-color); */
    width: 15rem;
    border-radius: 10rem;
    backdrop-filter: blur(10px);
    transition: all 0.8s;
}
#brand-wrapper:hover{
    background-color: var(--glass-color);
    box-shadow: 0px 0px 10px 0px var(--glow-color);
    backdrop-filter: blur(30px);
}
@media (max-width: 768px) {
    #brand-wrapper{

    }
}
.header-logo{
    width: 48px;
    top: 50%;
    transform: translate(0, -50%);
    left: 48px;
    position: absolute;
    z-index: 10;
}
.logo-glow{
    opacity: 0.7;
    z-index: 5;
    animation: logo-glow 3s infinite alternate;
}
#brand-wrapper:hover .logo-glow{
    opacity: 1;
}

#scta{
    position: relative;
    grid-area: scta;
    display: flex;
    align-items: center;
    text-shadow: 0px 0px 4px var(--glow-color);
    cursor: pointer;
    font-size: 1.2rem;
    gap: 10px;
}
#scta::before{
    display: block;
    content: " ";
    width: 36px;
    height: 36px;
    background: url("./LINE_logo.svg");
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-120%, -50%);
}
#scta>p{
    margin: 0;
}
#home-link{
    display: none;
    text-shadow: 0px 0px 4px var(--glow-color);
    cursor: pointer;
    font-size: 1.2rem;
}


@keyframes logo-glow{
    0% {filter: blur(10px) invert(42%) sepia(74%) saturate(479%) hue-rotate(158deg) brightness(101%) contrast(98%);}
    25% {filter: blur(2px) invert(42%) sepia(74%) saturate(479%) hue-rotate(158deg) brightness(101%) contrast(98%);}
    75% {filter: blur(8px) invert(42%) sepia(74%) saturate(479%) hue-rotate(158deg) brightness(101%) contrast(98%);}
    100% {filter: blur(5px) invert(42%) sepia(74%) saturate(479%) hue-rotate(158deg) brightness(101%) contrast(98%);}    
}

#apply-now{
    grid-area: cta;
    box-shadow: 0px 0px 4px 2px var(--theme-color);
    text-shadow: 0px 0px 4px var(--glow-color);
    background-color: var(--theme-glass-color);
    border: 1px solid transparent;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3rem;
    padding-right: 3rem;
}
#apply-now:hover{
    box-shadow: 0px 0px 12px 6px var(--theme-color);
    border: 1px solid var(--title);
}

#menu{
    max-width: 30px;
    display: none;
}

@media (max-width: 1090px) {
    .stander-header{
        grid-template-columns: auto 1fr auto;
        grid-template-areas: "logo ... nav";
        padding: 1rem 2rem;
    }
    #brand-wrapper{
        width: 12rem;
    }
    .header-logo{
        width: 32px;
        left: 24px;
    }
    #brand-name{
        left: 80px;
    }
    .header-nav{
        flex-direction: column;
        z-index: 900;
    }
    #menu{
        display: initial;
        z-index: 900;
    }
    #selector-container{
        display: none;
        position: fixed;
        top: 3rem;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 900;
        height: 4rem;
    }
    .nav-item{
        display: none;
        position: fixed;
        width: max-content;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 900;
    }
    #nav-item-1{
        top: 22rem;
    }
    #nav-item-2{
        top: 26rem;
    }
    #scta{
        display: none;
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        top: 17rem;
        z-index: 900;
    }
    #home-link{
        display: none;
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        top: 11.5rem;
        padding: 1rem 1rem;
        z-index: 900;
    }
    #apply-now{
        display: none;
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        top: 8rem;
        z-index: 900;
    }
    #backdrop{
        display: none;
        position: fixed;
        height: 100vh;
        width: 100vw;
        min-width: 300px;
        top: 0px;
        right: 0px;
        background-color: var(--backdrop-color);
        backdrop-filter: blur(10px);
        z-index: 20;
    }
    .displayFlex{
        display: flex !important;
    }
}
