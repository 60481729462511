#selector-container{
    position: relative;
    min-width: 10rem;
    height: 100%;
    grid-area: loca;
}
#current-location{
    font-size: 1.2rem;
    list-style-type: none;
    height: 100%;
    width: 100%;
    padding: 0rem 2rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}
#current-location>p{
    margin: auto;
    text-wrap: nowrap;
    display: block;
}
#current-location>img{
    width: 2.5rem;
}

#location-select{
    font-size: 1.2rem;
    list-style-type: none; 
    width: calc(100% - 2rem);
    position: absolute;
    top: 5rem;
    display: none;
    flex-direction: column;
    gap: 0.5rem;
    box-shadow: 0px 0px 4px 2px var(--glow-dim-color);
    background-color: var(--glass-color);
    backdrop-filter: blur(10px);
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    margin: 1rem;
    transition: all 0.5s;
}
#location-select>li{
    font-size: 1.2rem;
    text-wrap: nowrap;
    text-align: center;
    cursor: pointer;
}
.selecting{
    display: flex !important;
}