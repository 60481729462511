.arrow-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    transform: scale(80%);
    font-size: 1.5rem;
    font-weight: 600;
    box-shadow: 0px 0px 4px 2px var(--glow-color);
    height: max-content;
    padding: 1.8rem min(4rem, 10vw);
}
.arrow-in-btn{
    max-width: 40px;
    min-width: 10px;
    transform: rotate(90deg);
    opacity: 0.2;
    transition: all 0.3s;
}

.arrow-btn-focus{
    transform: scale(100%);
    box-shadow: 0px 0px 20px 10px var(--theme-color);
}
.arrow-btn-focus:hover{
    background-color: var(--theme-glass-color);
    box-shadow: 0px 0px 10px 5px var(--theme-color);
}

.arrow-btn-focus .arrow-in-btn{
    transform: rotate(0deg);
    opacity: 1;
}

@keyframes rotate-arrow {
    0%  {transform: rotate(90deg);}
    100%  {transform: rotate(0deg);}
}

