#standard-footer{
    background-color: var(--dark-glass-color);
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 10px 5px var(--shadow-color);
    width: 100%;
    position: absolute;
    bottom: 0px;
}

#footer-div{
    padding: 5rem 2rem;
}